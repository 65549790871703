<template>
  <div class="container_list">
    <clone-tab :bgcShow="bgcShow"></clone-tab>

    <!-- 数据列表 -->
    <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
      <div class="list_box" v-for="(item,index) in dataList" :key="index">
        <div class="list_item">
          <div class="list_left">
            <div class="list_img">
              <img :src="item.activity_image" />
            </div>
            <div class="list_num">已有{{item.person_sum}}人参加</div>
          </div>
          <div class="list_right">
            <div class="right_title">{{item.activity_title}}</div>
            <div class="list_time">
              <div>{{item.start_time}}-{{item.end_time}}</div>
              <!-- <div v-html="item.activity_text"></div> -->
            </div>
            <div class="details" v-if="item.apply_status==1" @click="details(item.id)">活动详情</div>
            <div class="details1" v-if="item.activity_status==2&&item.apply_status!=3" @click="details(item.id)">活动已结束</div>
            <div class="details1" v-if="item.apply_status==3" @click="details(item.id)">活动已取消</div>
            <div class="details" style="background:rgba(245, 97, 97, 1)" v-if="item.activity_status!=2&&item.apply_status==2">取消审核中</div>
          </div>
        </div>
      </div>
    </vue-loadmore>
  </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import { applyList_api } from "@/api/deal";
export default {
  data() {
    return {
      bgcShow: true,
      dataList: [],
      query:{
        limit: 10,
        page: 1,
      },
      finished: false,
    };
  },
  components: {
    cloneTab
  },
  created() {
    document.title = "活动列表";
    this.getList();
  },
  methods: {
    onRefresh(done) {
      this.dataList = [];
      this.query.page = 1;
      this.finished = false;
      this.fetch();

      done();
    },

    onLoad(done) {
      if (this.query.page <= 10) {
        this.fetch();
      } else {
        // all data loaded
        this.finished = true;
      }
      done();
    },

    fetch() {
      this.query.page++;
      this.getList();
    },
    getList() {
      applyList_api(this.query).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.dataList = this.dataList.concat(res.data)
        }
      });
    },
    details(id) {
      this.$router.push(`./activityDetails?id=${id}`);
    }
  }
};
</script>
<style>
.container_list {
  padding-top: 30px;
  box-sizing: border-box;
}
.list_item {
  width: 684px;
  height: 301px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
  opacity: 1;
  border-radius: 0px;
  padding: 19px 17px;
  box-sizing: border-box;
  display: flex;
}
.list_box {
  margin: 30px 33px;
}
.list_img {
  width: 230px;
  height: 230px;
  background-color: pink;
}
.list_img img {
  width: 100%;
  height: 100%;
}
.list_num {
  color: #303030;
  font-size: 20px;
  margin-top: 10px;
  width: 230px;
  text-align: center;
}
.list_time {
  font-size: 20px;
  color: #303030;
  margin: 50px 0 22px;
}
.list_time div {
  margin-bottom: 11px;
  width: 260px;
}
.list_right {
  margin-left: 23px;
}
.right_title {
  font-size: 30px;
  color: #303030;
}
.details {
  width: 140px;
  height: 50px;
  background: #f66009;
  opacity: 1;
  border-radius: 27px;
  text-align: center;
  line-height: 50px;
  color: #ffffff;
  font-size: 20px;
  margin-left: 220px;
}
.details1 {
  width: 140px;
  height: 50px;
  background: rgba(213, 212, 211, 1);
  opacity: 1;
  border-radius: 27px;
  text-align: center;
  line-height: 50px;
  color: #ffffff;
  font-size: 20px;
  margin-left: 220px;
}
</style>
